<!--
 * @Description: 转移
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-18 14:31:18
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-01 15:53:38
-->
<template>
  <span>
    <el-button
      type="success"
      class="hq-button"
      icon="el-icon-s-check"
      @click="transferClick"
      :disabled="setTransrefseach"
      >查询转移</el-button
    >
    <HqModal :visible="transferShow" title="转移" width="480" @on-cancel="cancel">
      <el-form ref="form" :model="form" :rules="formRules">
        <el-row :gutter="10">
          <el-col :span="24" prop="mode">
            <el-form-item>
              <CheckBox 
              ref="CheckBoxname"
              label="规则" 
              :list="checkList"
              v-bind:select.sync="form.mode" />
            </el-form-item>
          </el-col>
          <el-col :span="24" style="margin-top: 14px" v-if="form.mode !== 2">
            <el-form-item prop="deptId">
              <SelectTree
                ref="deptsys"
                labelName="部门"
                :data="deptList"
                label="deptName"
                value="deptId"
                @update:select="select"
                v-bind:select.sync="form.deptId" />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="permUtil.WbCusotmer.transferChange() && form.mode == 1" >
              <el-checkbox-group v-model="sign">
                <el-checkbox label="是否变更部门" :value="1">是否变更部门</el-checkbox>
              </el-checkbox-group>
          </el-col>
          <el-col :span="24" style="margin-top: 14px" v-if="form.mode == 1">
            <el-form-item prop="userId">
              <SelectMultiple 
                ref="user"
                v-bind:select.sync="form.userId"
                label="人员"
                :items="userArr"
                itemText="userName"
                itemValue="userId" />
            </el-form-item>
          </el-col>
          
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" :loading="loadingbtn" type="primary">确认</el-button>
      </div>
    </HqModal>

    <HqModal :visible="transferShowtishi" title="转移" width="420" @on-cancel="canceltishi">
      <div>
        <!-- 您确定按照所选查询条件操作转移该批商机吗？ -->
        根据查询条件，当前符合转移条件的商机有<span class="spantitle">{{total}}</span>条，
        <span style="color: red;">转移操作为不可逆操作</span>，请确认是否转移？
      </div>
       <div slot="footer">
        <el-button @click="canceltishi">取消</el-button>
        <el-button @click="savetishi" type="primary">确认</el-button>
      </div>
    </HqModal>
  </span>
</template>
<script>
import SelectTree from "@/components/select-tree";
import CheckBox from "@/components/check-box";
import SelectMultiple from "components/select-multiple";
import { getUserByDeptId, transferDeptTree, trackTypeDeptTree } from "api/public";
import { doTransferCustomersearch } from "api/customer";
import { mapActions, mapGetters } from "vuex";


export default {
  name: "transfersearch",
  components: {
    SelectTree,
    CheckBox,
    SelectMultiple,
  },
  props: {
    currList: {
      type: Array,
    },
    method: {
      type: Function,
    },
    detailInit: {
      type: Function,
    },
    searchTranch: {
      type: Boolean
    },
    pageParam: {
      type: Object,
    },
    searchParam: {
      type: Object,
    },
    total: {
      type: Number,
    },
    exportType: {
      type: Number,
    },
    setTransrefseach: {
      type:Boolean
    }
  },
  computed: {
    ...mapGetters(["loginUser"]),
  },
  data() {
    return {
      transferShow: false,
      loadingbtn: false,
      form: {
        userId: null,
        deptId: "",
        mode: 1,
        sign: 0
      },
      checkList: [
        { label: "按人员1:1分配", value: 1 },
        { label: "按号码归属地校区转移", value: 2 },
        { label: "按部门转移", value: 3 },
      ],
      tree: [],
      customerArr: [],
      userArr: [],
      formRules: {
        deptId: [{ required: true, message: "请选择部门" }],
        userId: [{ required: true, message: "请选择人员" }],
        mode: [{ required: true, message: "请选择规则" }]
      },
      list: null,
      sign: 0,
      deptList: [],
      transferShowtishi: false,
      mode: 1,
      page: {}
    };
  },
  methods: {
    init(list) {
      console.log('list',list);
      this.list = list
      this.customerArr = []
      this.form.customerId = []
      list.map(k => {
          if(k.status === 4) {
          this.customerArr.push(4)
        } else {
          this.customerArr.push(2)
          this.form.customerId.push(k.customerId)
        }
      })
      console.log('this.customerArr-----', this.customerArr);

      if (this.customerArr.indexOf(4) !== -1) {
        if (this.customerArr.length > 1) {
          this.$message.error('包含有冻结状态商机，请勿操作');
        } else if (this.customerArr.length == 1) {
          this.$message.error('该商机处于冻结状态，请勿操作');
        }
      }
    },
    canceltishi() {
      this.transferShowtishi = false
    },
    savetishi() {
      console.log('this.list', this.list);
      if (this.list) {
        this.form.customerId = []
      //   this.list.map(k => {
        //     if(k.status !== 4) {
          //       this.form.customerId.push(k.customerId)
      //   }
      // })
      }
        this.transferShowtishi = false
      
      this.page = {
        ...this.pageParam, 
        ...this.searchParam, 
        ...{exportType: this.exportType, total: this.total}
      }
      this.transferShowtishi = false
      this.form.userId = []
      this.form.deptId = "" 
      this.userId = []
      this.form.mode = 1
      this.transferShow = false;
      this.$refs.deptsys.init()
      this.$refs.user.init()
      this.$refs.CheckBoxname.set(1)
      console.log('this.page', this.page);
      console.log('this.form', this.form);
      // transferDeptTree({ isRoot: false }).then((data) => this.deptList = data.result.tree);
      trackTypeDeptTree().then((data) => this.deptList = data.result.tree)

      this.transferShow = true
    },
    transferClick() {
      // this.cancel()
      console.log('list', this.list, this.searchTranch);
      console.log('this.pageParam', this.pageParam, this.total,this.loginUser.trackType);
      if(this.loginUser.trackType == 2) {
        this.checkList = [{ label: "按人员1:1分配", value: 1 },{ label: "按部门转移", value: 3 },]
      } else {
        this.checkList = [{ label: "按人员1:1分配", value: 1 },{ label: "按号码归属地校区转移", value: 2 },{ label: "按部门转移", value: 3 }]
      }
      if (this.searchTranch == true) {
        this.transferShowtishi = true
      } else {
        if (this.list) {
          this.transferShowtishi = false
          this.form.customerId = []
          this.list.map(k => {
            if(k.status !== 4) {
              this.form.customerId.push(k.customerId)
          }
        })
        }
        this.page = {
          ...this.pageParam, 
          ...this.searchParam, 
          ...{exportType: this.exportType, total: this.total}
        }
        this.form.userId = []
        this.form.deptId = "" 
        this.userId = []
        this.form.mode = 1
         this.transferShow = false;
        this.$refs.deptsys.init()
        this.$refs.user.init()
        this.$refs.CheckBoxname.set(1)
        console.log('this.form', this.form);
        // transferDeptTree({ isRoot: false }).then((data) => this.deptList = data.result.tree);
        trackTypeDeptTree().then((data) => this.deptList = data.result.tree)

        this.transferShow = true
        this.$forceUpdate()
      }
    },
    select(deptId) {
      this.$refs.user.init()
      this.weightsUser = [];
      
      this.form.deptId = deptId;
      this.userArr = []
      if(deptId) {
        getUserByDeptId({ treeValue: deptId }).then(data => {
          this.userArr = data.result;
          // if (data.data.result) {
          //   this.userArr.unshift(this.allSelect);
          //   let arr = [];
          //   data.data.result.forEach(item => {
          //     arr.push(item.userId);
          //   });
          //   this.allUserId = arr;
          // }
        }); 
      }
      // this.form.userId = arr;
      
      console.log('this.form选择树', this.form);
    },
    // 保存
    save() {
      this.form.sign = this.sign?1:0
      this.$refs['form'].validate(async (valid) => {
        if(valid) {
          this.form.page = this.page
          if(!this.form.userId) {
            this.form.userId = []
          }
          this.loadingbtn = true
          console.log('form保存', this.form);
          doTransferCustomersearch(this.form, this.page)
          .then(data => {
            let { code, msg } = data;
            
            if (code === 0) {
              
              this.transferShow = false;
              this.loadingbtn = false
              
              this.$message.success(msg);
              
              this.method && this.method();
              // console.log(this.detailInit);
              this.page = {}
              this.form.mode = 1
              this.detailInit && this.detailInit(this.list[0])
              this.cancel()
            } else {
              this.$message.error(msg);
            }
          })
          .catch(() => {
            this.transferShow = false;
            this.loadingbtn = false
          });
        }
      })
    },
    cancelx() {
      this.transferShow = false;
      this.form = {
        userId: "",
        deptId: "",
        mode: 1
      }
      console.log('this.list',this.list);
      this.$refs.deptsys.init()
      this.$refs.user.init()
      console.log('this.form',this.form);
    },
    // 取消
    cancel() {
      this.transferShow = false;
      this.form = {
        userId: [],
        deptId: "",
        mode: 1
      }
      console.log('this.list',this.$refs);
      this.$refs.deptsys.init()
      this.$refs.user.init()
      console.log('this.form',this.form);
    },
  },
}
</script>

<style lang="scss">
  .spantitle {
    color: red;
    font-size: 20px;
  }
</style>
