var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "label_tag" },
    [
      _c("span", [_vm._v("常用客户标签：")]),
      _vm._l(_vm.items, function(item) {
        return [
          _c(
            "el-badge",
            {
              key: item.defLabelId,
              staticStyle: { "margin-right": "14px", cursor: "pointer" },
              attrs: {
                value: item.count ? item.count : "",
                max: 99,
                type: "danger"
              }
            },
            [
              _c(
                "el-tag",
                {
                  style: { color: item.click ? "#fff" : "" },
                  attrs: {
                    color: item.click ? "#8080ff" : "",
                    type: item.color,
                    effect: "plain"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.serachClick(item)
                    }
                  }
                },
                [_vm._v(_vm._s(item.defLabelName))]
              )
            ],
            1
          )
        ]
      }),
      _c(
        "span",
        {
          staticStyle: { color: "#47cb89", cursor: "pointer" },
          on: { click: _vm.addLabelClick }
        },
        [_vm._v("+设置常用标签")]
      ),
      _c(
        "HqModal",
        {
          attrs: { width: "550", visible: _vm.labelVisible },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c("div", { staticClass: "main_label" }, [
            _c(
              "div",
              { staticClass: "main_box" },
              _vm._l(_vm.navList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "li",
                    class: { active: index == _vm.num },
                    on: {
                      click: function($event) {
                        return _vm.tab(index)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "tabCon" },
              [
                _c("span", { staticClass: "tab_con_clue" }, [
                  _vm._v("提示：1、最多可选6个常用标签，按照选择先后排序")
                ]),
                _c("p", { staticClass: "tab_con_clue" }, [
                  _vm._v("      2、点击标签，选中。再次点击，取消选择")
                ]),
                _vm._l(_vm.tabContents, function(i, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: 0 == _vm.num,
                          expression: "0 == num"
                        }
                      ],
                      key: index
                    },
                    [
                      _c("li", { staticClass: "tab_com_li" }, [
                        _vm._v(_vm._s(i.titleName))
                      ]),
                      i.type === "sys"
                        ? _c(
                            "div",
                            { staticClass: "tab_com_tag" },
                            [
                              _vm._l(i.sysLabels, function(v) {
                                return [
                                  (_vm.seeSelect
                                  ? v.used
                                  : true)
                                    ? _c(
                                        "span",
                                        {
                                          key: v.sysLabelId,
                                          staticClass: "tag_list",
                                          style: {
                                            backgroundColor:
                                              "" + (v.used ? "#19be6b" : ""),
                                            color: "" + (v.used ? "#fff" : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.tagListClick(v)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(v.sysLabelName))]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      i.type === "biz"
                        ? _c(
                            "div",
                            { staticClass: "tab_com_tag" },
                            [
                              _vm._l(i.bizCustomerLabels, function(v) {
                                return [
                                  (_vm.seeSelect
                                  ? v.used
                                  : true)
                                    ? _c(
                                        "span",
                                        {
                                          key: v.labelId,
                                          staticClass: "tag_list",
                                          style: {
                                            backgroundColor:
                                              "" + (v.used ? "#19be6b" : ""),
                                            color: "" + (v.used ? "#fff" : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.tagListClick(v)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(v.name))]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                }),
                _vm._l(_vm.tabContents, function(item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: index + 1 == _vm.num,
                          expression: "(index + 1) == num"
                        }
                      ],
                      key: index + item.titleName
                    },
                    [
                      _c("li", { staticClass: "tab_com_li" }, [
                        _vm._v(_vm._s(item.titleName))
                      ]),
                      item.type === "sys"
                        ? _c(
                            "div",
                            { staticClass: "tab_com_tag" },
                            [
                              _vm._l(item.sysLabels, function(v) {
                                return [
                                  (_vm.seeSelect
                                  ? v.used
                                  : true)
                                    ? _c(
                                        "span",
                                        {
                                          key: v.sysLabelId,
                                          staticClass: "tag_list",
                                          style: {
                                            backgroundColor:
                                              "" + (v.used ? "#19be6b" : ""),
                                            color: "" + (v.used ? "#fff" : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.tagListClick(v)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(v.sysLabelName))]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      item.type === "biz"
                        ? _c(
                            "div",
                            { staticClass: "tab_com_tag" },
                            [
                              _vm._l(item.bizCustomerLabels, function(v) {
                                return [
                                  (_vm.seeSelect
                                  ? v.used
                                  : true)
                                    ? _c(
                                        "span",
                                        {
                                          key: v.labelId,
                                          staticClass: "tag_list",
                                          style: {
                                            backgroundColor:
                                              "" + (v.used ? "#19be6b" : ""),
                                            color: "" + (v.used ? "#fff" : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.tagListClick(v)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(v.name))]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-right": "220px" },
                  on: { change: _vm.checkChange },
                  model: {
                    value: _vm.single,
                    callback: function($$v) {
                      _vm.single = $$v
                    },
                    expression: "single"
                  }
                },
                [_vm._v("仅查看已选标签")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.addloading
                  },
                  on: { click: _vm.saveClick }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }