<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-18 10:33:24
 * @LastEditors: Seven
 * @LastEditTime: 2021-11-29 11:43:06
-->
<template>
  <span>
    <el-button
      type="primary"
      class="hq-button"
      icon="el-icon-folder-add"
      @click="addClick"
      >新增</el-button
    >
    <HqDrawer ref="drawer" :visible="addShow" title="新增客户" size="60%" @on-cancel="cancel">
      <div style="width: 90%;margin: 0 auto;padding: 12px 0 40px 0">
        <el-form ref="form" :model="form" :rules="addruleValidate">
          <el-row :gutter="24">
            <el-col :span="24" v-if="userTrackType === 0">
              <el-form-item prop="trackType"> 
                <dict-select code="BIZ_TYPE">
                  <v-select
                    v-model="form.trackType"
                    slot-scope="props"
                    :items="props.dictData"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="赛道"
                    item-text="label"
                    item-value="value"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    @change="trackTypeChange"
                  ></v-select>
                </dict-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="name">
                <v-text-field
                  v-model="form.name"
                  label="学员姓名"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="address">
                  <v-text-field
                  v-model="form.address"
                  label="地址"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="sex">
                <dict-select code="SEX">
                  <v-select
                    v-model="form.sex"
                    slot-scope="props"
                    :items="props.dictData"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="性别"
                    item-text="label"
                    item-value="value"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                  ></v-select>
                </dict-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="weiXin">
                <v-text-field
                  v-model="form.weiXin"
                  label="微信"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="phone">
                <v-text-field
                  v-model="form.phone"
                  label="手机"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="qq">
                <v-text-field
                  v-model="form.qq"
                  label="QQ"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="landLine" style="margin-bottom: 44px">
                <v-text-field
                  v-model="form.landLine"
                  label="座机"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="education">
                <dict-select code="EDUCATION_TYPE">
                  <v-select
                    v-model="form.education"
                    slot-scope="props"
                    :items="props.dictData"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="学历"
                    item-text="label"
                    item-value="value"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                  ></v-select>
                </dict-select>
              </el-form-item>
            </el-col>
            <!-- 自考项目 -->
            <div v-if="form.trackType === 2 || userTrackType === 2">
              <el-col :span="24">
                <el-form-item >
                  <v-autocomplete
                    v-model="form.recruitChannels"
                    :items="recruitChannelsListzikao"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="渠道名称"
                    item-text="name"
                    item-value="id"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                  ></v-autocomplete>
                </el-form-item>
              </el-col>
            </div>
            <!-- 财经项目 -->
            <div v-if="form.trackType === 1 || userTrackType === 1">
              <!-- <el-col :span="24">
                <el-form-item prop="channels">
                    <v-autocomplete
                    v-model="form.channels"
                    :items="channelsList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="代理网名称"
                    item-text="name"
                    item-value="id"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                  ></v-autocomplete>
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item prop="recruitChannels">
                  <v-autocomplete
                    v-model="form.recruitChannels"
                    :items="recruitChannelsList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="渠道名称"
                    item-text="name"
                    item-value="id"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                  ></v-autocomplete>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item prop="oldStudentPhone" style="margin-bottom: 44px">
                  <v-text-field
                    v-model="form.oldStudentPhone"
                    label="老学员手机"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                  ></v-text-field>
                </el-form-item>
              </el-col>
            </div>
            <div v-if="form.recruitChannels === 1723">
              <el-col :span="24">
                <el-form-item prop="flowPartnerCode">
                  <v-autocomplete
                    v-model="form.flowPartnerCode"
                    :items="partnerList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="合伙人（流量归属人）"
                    item-text="flowPartner"
                    item-value="flowPartnerCode"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    @change="partnerClick"
                  ></v-autocomplete>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item prop="flowPartnerPhone">
                  <v-text-field
                  v-model="form.flowPartnerPhone"
                  label="合伙人电话"
                  hide-details="auto"
                  readonly
                  outlined
                  :dense="true"
                  :height="32"
                ></v-text-field>
                </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
              <el-form-item prop="economicFlowPartner">
                <v-text-field
                  v-model="form.economicFlowPartner"
                  label="经纪合伙人（第二流量归属人）"
                  hide-details="auto"
                  readonly
                  outlined
                  :dense="true"
                  :height="32"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="economicFlowPartnerPhone">
                <v-text-field
                  v-model="form.economicFlowPartnerPhone"
                  label="经纪合伙人电话"
                  hide-details="auto"
                  readonly
                  outlined
                  :dense="true"
                  :height="32"
                ></v-text-field>
              </el-form-item>
            </el-col> -->
            </div>
            <!-- <div v-if="form.recruitChannels === 1733 || form.recruitChannels === 1742">
              <el-col :span="24">
                <el-form-item prop="flowPartner">
                  <v-text-field
                    v-model="form.flowPartner"
                    :label="form.recruitChannels === 1733?'经纪合伙人（流量归属人）':'地推人员（流量归属人）'"
                    hide-details="auto"
                    readonly
                    outlined
                    :dense="true"
                    :height="32"
                  ></v-text-field>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item prop="flowPartnerPhone">
                  <v-text-field
                    v-model="form.flowPartnerPhone"
                    :label="form.recruitChannels === 1733?'经纪合伙人电话':'地推人员电话'"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                  ></v-text-field>
                </el-form-item>
              </el-col>
            </div> -->
            <el-col :span="24">
              <el-form-item prop="deptId">
                <SelectTree
                  ref="deptsys"
                  :data="deptList"
                  label="deptName"
                  value="deptId"
                  labelName="部门"
                  @update:select="select"
                  v-bind:select.sync="form.deptId" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="userId">
                <v-autocomplete
                  v-model="form.userId"
                  :items="userList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="人员"
                  outlined
                  item-text="userName"
                  item-value="userId"
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  rounded
                ></v-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="url">
                <v-text-field
                  v-model="form.url"
                  label="URL"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="memo">
                <v-textarea
                  v-model="form.memo"
                  outlined
                  :height="80"
                  name="input-7-4"
                  label="备注"
                  value=""
                ></v-textarea>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" type="primary">确认</el-button>
      </div>
    </HqDrawer>
  </span>
</template>
<script>
import ValidateType from "@/utils/validate";
import SelectTree from "@/components/select-tree";
import dictSelect from "@/components/dictSelect";
import { getDeptByTrackType, getUserByDeptId, getflowPartnerList, getZikaoTrackType } from "api/public";
import { doAddCustomer } from "api/customer";

export default {
  name: "add",
  components: {
    SelectTree,
    dictSelect
  },
  props: {
    channelsList: {
      type: Array,
    },
    recruitChannelsList: {
      type: Array,
    },
    method: {
      type: Function
    }
  },
  data() {
    const validateFlowPartner = (rule, value, callback) => {
      let content = ''
      if (!value) {
        if(this.form.recruitChannels === 1723) {
          content = '请填写合伙人姓名'
        } else if(this.form.recruitChannels === 1733) {
          content = '请填写经济合伙人姓名'
        } else if(this.form.recruitChannels === 1742) {
          content = '请填写地推人员（流量归属人）'
        }
        return callback(new Error(content));
      } else {
        return callback();
      }
    }
    const validateOldStudentPhone = (rule, value, callback) => {
      
      if (value) {
        const reg = /^1[3-9]\d{9}$/
        const texterr = '手机号码格式不正确，数字1开头，第二位为3～9的长度为纯11位数字'
        if(!reg.test(value)) {
          return callback(texterr)  
        } else {
          return callback()
        }
      } else {
        return callback();
      }
    }
    return {
      addShow: false,
      form: {
        name: '',
        address: '',
        phone: '',
        landLine: '',
        sex: '',
        qq: '',
        url: '',
        memo: '',
        deptId: '',
        deptName: '',
        userId: '',
        weiXin: '',
        status: '',
        type: '',
        trackType: '',
        education: '',
        channels: '',
        recruitChannels: '',
        flowPartnerCode: '',
        flowPartner: '',
        flowPartnerPhone: '',
      },
      deptSelectData: [],
      addruleValidate: {
        phone: [
          { validator: ValidateType.validatePhone, trigger: 'change', required: false, }
        ],
        landLine: [
          { validator: ValidateType.validateTel, trigger: 'change', required: false, }
        ],
        qq: [
          { validator: ValidateType.validateQQ, trigger: 'change', required: false, }
        ],
        weiXin: [
          { validator: ValidateType.validateweiXin, trigger: 'change', required: false, }
        ],
        sex: [{ message: '请选择性别', required: true }],
        deptId: [
          { message: '请选择部门', required: true }
        ],
        userId: [
          { message: '请选择人员', required: true }
        ],
        name: [
          { message: '请填写学员姓名', required: true }
        ],
        trackType: [
          {message: '请选择赛道', required: true}
        ],
        // channels: [
        //   {message: '请选择渠道名称', type: 'number', required: true}
        // ],
        oldStudentPhone: [
          { validator: validateOldStudentPhone, trigger: 'change' }
        ],
        recruitChannels: [
          {message: '请选择招生渠道', type: 'number', required: true}
        ],
        flowPartnerCode: [
          // { message: '请填写合伙人姓名', required: true }
          { required: true, validator: validateFlowPartner, trigger: 'blur'}
        ],
        flowPartnerPhone: [
          // { message: '请填写合伙人电话', required: true }
          {required: true, validator: ValidateType.validatePhoneOrTel, trigger: 'blur'}
        ],
        secondFlowPartner: [
          { message: '请填写经纪合伙人姓名', required: true }
        ],
        secondFlowPartnerPhone: [
          // { message: '请填写经纪合伙人电话', required: true }
          {required: true, validator: ValidateType.validatePhoneOrTel, trigger: 'blur'}
        ],
      },
      deptList: [],
      userList: [],
      partnerList: [],
      recruitChannelsListzikao: []
    };
  },
  computed: {
    userTrackType() {
      return this.$store.state.user.loginUser.trackType
    }
  },
  mounted() {
    this.getZiKaoTrack(2)
    getflowPartnerList().then(data => {
      this.partnerList = data.result
      this.partnerList.map((i) => {
        if (i.flowPartner == '王波') {
          console.log('进入了' + i.flowPartner);
        }
      })
    })
    setTimeout(() => {
      this.initDeptment()
    }, 2000)
  },
  methods: {
    // 获取自考赛道的渠道名称
    getZiKaoTrack(trackType) {
      console.log('trackType', trackType);
      getZikaoTrackType(trackType).then(res => {
        console.log('res', res);
        this.recruitChannelsListzikao = res.result
      })
    },
    // 一开始获取部门和人员展示
    getdeptSelect() {
      let params = {trackType: this.$store.state.user.loginUser.trackType, isRoot: false}
      getDeptByTrackType(params).then(res => {
        if (res.code === 0) {
          this.deptList = res.result.tree
          this.$nextTick(() => {
            this.$store.state.user.loginUser.deptName && this.$refs.deptsys.set(this.$store.state.user.loginUser.deptName);
          })
          this.getdeptuser()
        }
      }).catch(error => {
        console.log(error);
      })
    },
    // 获取部门
    getdeptuser() {
      getUserByDeptId({treeValue: this.$store.state.user.loginUser.deptId}).then((data) => {
        this.form.userId = this.$store.state.user.loginUser.userId
        // this.form.userId = 28763
        this.form.deptId = this.$store.state.user.loginUser.deptId
        console.log('this.form.userId', this.form.userId);
        console.log('this.form.deptId', this.form.deptId);
        console.log('this.$store.state.user.loginUser', this.$store.state.user.loginUser);
        this.userList = data.result
      })
    },
    addClick() {
      this.$refs.drawer && this.$refs.drawer.init()
      if (this.$store.state.user.loginUser.roleList[0].roleCode !== 'ADMIN') {
        this.getdeptSelect()
      }
    },
    partnerClick(value) {
      console.log('value',value);
      this.partnerList.map(item => {
        if(item.flowPartnerCode === value) {
          this.form.flowPartnerCode = item.flowPartnerCode
          this.form.flowPartner = item.flowPartner
          this.form.flowPartnerPhone = item.flowPartnerPhone
        }
      })
      console.log('form.flowPartner',this.form.flowPartner);
      console.log('this.form', this.form);
      this.$forceUpdate()
    },
    // 初始化部门
    initDeptment () {
      if (this.userTrackType !== 0) {
        this.form.trackType = this.userTrackType
        this.trackTypeChange()
      }
    },
    // 赛道联动部门
    trackTypeChange() {
      console.log('this.form.trackType', this.form.trackType);
      const params = {trackType: this.form.trackType, isRoot: false}
      this.form.deptId = ''
      this.$nextTick(() => {
        this.$refs.deptsys && this.$refs.deptsys.init()
      })
      this.form.userId = ''
      if (this.form.trackType == 2) {
        this.getZiKaoTrack(this.form.trackType)
      }
      getDeptByTrackType(params).then(res => {
        if (res.code === 0) {
          this.deptList = res.result.tree
        }
      }).catch(error => {
        console.log(error);
      })
    },
    // 部门联动人员
    // 部门查询级联选择
    select (deptId) {
      this.form.deptId = deptId
      this.form.userId = ''
      if(deptId == '') {
        this.userList = []
      } else {
        getUserByDeptId({treeValue: deptId}).then((data) => this.userList = data.result)
      }
    },
    IsRelus () {
      let rules = ['qq', 'phone', 'weiXin', 'landLine']
      for (let i = 0; i < rules.length; i++) {
        if (this.form[rules[i]] == '' || this.form[rules[i]] == undefined) {
          this.Isflag = false
        } else {
          this.Isflag = true
          return false
        }
      }
    },
    // 保存
    save() {
      const _this = this;
      _this.IsRelus()
      this.$refs['form'].validate((valid) => {
        if(valid) {
          if (!_this.Isflag) {
            this.$message.error('请至少填写一个联系方式')
            return false
          }
          this.addloading = true
            let channelsName = ''
            let recruitChannelsName = ''
            this.channelsList.forEach(item => {
              if (item.id === this.form.channels) {
                channelsName = item.name
              }
            })
            this.recruitChannelsList.forEach(item => {
              if (item.id === this.form.recruitChannels) {
                recruitChannelsName = item.name
              }
            })
            doAddCustomer({...this.form, channelsName, recruitChannelsName}).then((data) => {
              let { code, msg } = data
              if (code === 0) {
                this.$message.success('新增成功')
                this.method()
                this.form.secondFlowPartner = ''
                this.form.secondFlowPartnerPhone = ''
                this.cancel()
              } else if (code === 5002) {
                this.$message.info("商机已存在！")
              } else {
                this.$message.error(msg)
                this.cancel()
              }
            })
        }
      })
    },
    // 取消
    cancel() {
      this.addShow = false;
      this.form = {
        name: '',
        address: '',
        phone: '',
        landLine: '',
        sex: '',
        qq: '',
        url: '',
        memo: '',
        deptId: '',
        deptName: '',
        userId: '',
        weiXin: '',
        status: '',
        type: '',
        trackType: '',
        education: '',
        channels: '',
        recruitChannels: '',
        flowPartnerCode: '',
        flowPartner: '',
        flowPartnerPhone: '',
      }
      this.$refs.deptsys.init()
      this.$refs.drawer.close()
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 26px !important;
}
::v-deep .el-form-item__error {
  padding-left: 10px !important;
}
</style>
