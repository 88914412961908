var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-button",
        {
          staticClass: "hq-button",
          attrs: { type: "primary", icon: "el-icon-folder-add" },
          on: { click: _vm.addClick }
        },
        [_vm._v("新增")]
      ),
      _c(
        "HqDrawer",
        {
          ref: "drawer",
          attrs: { visible: _vm.addShow, title: "新增客户", size: "60%" },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "90%",
                margin: "0 auto",
                padding: "12px 0 40px 0"
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, rules: _vm.addruleValidate }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _vm.userTrackType === 0
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "trackType" } },
                                [
                                  _c("dict-select", {
                                    attrs: { code: "BIZ_TYPE" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(props) {
                                            return _c("v-select", {
                                              attrs: {
                                                items: props.dictData,
                                                "menu-props": {
                                                  bottom: true,
                                                  offsetY: true
                                                },
                                                label: "赛道",
                                                "item-text": "label",
                                                "item-value": "value",
                                                outlined: "",
                                                dense: true,
                                                height: 32,
                                                clearable: true,
                                                "clear-icon": "$clear",
                                                "hide-details": "auto"
                                              },
                                              on: {
                                                change: _vm.trackTypeChange
                                              },
                                              model: {
                                                value: _vm.form.trackType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "trackType",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.trackType"
                                              }
                                            })
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3721978425
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "name" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "学员姓名",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "address" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "地址",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true
                                },
                                model: {
                                  value: _vm.form.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "address", $$v)
                                  },
                                  expression: "form.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "sex" } },
                            [
                              _c("dict-select", {
                                attrs: { code: "SEX" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return _c("v-select", {
                                        attrs: {
                                          items: props.dictData,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "性别",
                                          "item-text": "label",
                                          "item-value": "value",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto"
                                        },
                                        model: {
                                          value: _vm.form.sex,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "sex", $$v)
                                          },
                                          expression: "form.sex"
                                        }
                                      })
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "weiXin" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "微信",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true
                                },
                                model: {
                                  value: _vm.form.weiXin,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "weiXin", $$v)
                                  },
                                  expression: "form.weiXin"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "phone" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "手机",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true
                                },
                                model: {
                                  value: _vm.form.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "phone", $$v)
                                  },
                                  expression: "form.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "qq" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "QQ",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true
                                },
                                model: {
                                  value: _vm.form.qq,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "qq", $$v)
                                  },
                                  expression: "form.qq"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "44px" },
                              attrs: { prop: "landLine" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "座机",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true
                                },
                                model: {
                                  value: _vm.form.landLine,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "landLine", $$v)
                                  },
                                  expression: "form.landLine"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "education" } },
                            [
                              _c("dict-select", {
                                attrs: { code: "EDUCATION_TYPE" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return _c("v-select", {
                                        attrs: {
                                          items: props.dictData,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "学历",
                                          "item-text": "label",
                                          "item-value": "value",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto"
                                        },
                                        model: {
                                          value: _vm.form.education,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "education", $$v)
                                          },
                                          expression: "form.education"
                                        }
                                      })
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.form.trackType === 2 || _vm.userTrackType === 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.recruitChannelsListzikao,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "渠道名称",
                                          "item-text": "name",
                                          "item-value": "id",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto"
                                        },
                                        model: {
                                          value: _vm.form.recruitChannels,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "recruitChannels",
                                              $$v
                                            )
                                          },
                                          expression: "form.recruitChannels"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.trackType === 1 || _vm.userTrackType === 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "recruitChannels" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.recruitChannelsList,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "渠道名称",
                                          "item-text": "name",
                                          "item-value": "id",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto"
                                        },
                                        model: {
                                          value: _vm.form.recruitChannels,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "recruitChannels",
                                              $$v
                                            )
                                          },
                                          expression: "form.recruitChannels"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "44px" },
                                      attrs: { prop: "oldStudentPhone" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "老学员手机",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true
                                        },
                                        model: {
                                          value: _vm.form.oldStudentPhone,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "oldStudentPhone",
                                              $$v
                                            )
                                          },
                                          expression: "form.oldStudentPhone"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.recruitChannels === 1723
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "flowPartnerCode" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.partnerList,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "合伙人（流量归属人）",
                                          "item-text": "flowPartner",
                                          "item-value": "flowPartnerCode",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto"
                                        },
                                        on: { change: _vm.partnerClick },
                                        model: {
                                          value: _vm.form.flowPartnerCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "flowPartnerCode",
                                              $$v
                                            )
                                          },
                                          expression: "form.flowPartnerCode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "flowPartnerPhone" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "合伙人电话",
                                          "hide-details": "auto",
                                          readonly: "",
                                          outlined: "",
                                          dense: true,
                                          height: 32
                                        },
                                        model: {
                                          value: _vm.form.flowPartnerPhone,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "flowPartnerPhone",
                                              $$v
                                            )
                                          },
                                          expression: "form.flowPartnerPhone"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "deptId" } },
                            [
                              _c("SelectTree", {
                                ref: "deptsys",
                                attrs: {
                                  data: _vm.deptList,
                                  label: "deptName",
                                  value: "deptId",
                                  labelName: "部门",
                                  select: _vm.form.deptId
                                },
                                on: {
                                  "update:select": [
                                    _vm.select,
                                    function($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "deptId",
                                        $event
                                      )
                                    }
                                  ]
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "userId" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.userList,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "人员",
                                  outlined: "",
                                  "item-text": "userName",
                                  "item-value": "userId",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto",
                                  rounded: ""
                                },
                                model: {
                                  value: _vm.form.userId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "userId", $$v)
                                  },
                                  expression: "form.userId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "url" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "URL",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true
                                },
                                model: {
                                  value: _vm.form.url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "url", $$v)
                                  },
                                  expression: "form.url"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "memo" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  height: 80,
                                  name: "input-7-4",
                                  label: "备注",
                                  value: ""
                                },
                                model: {
                                  value: _vm.form.memo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "memo", $$v)
                                  },
                                  expression: "form.memo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }