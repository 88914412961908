<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-09-15 10:42:17
 * @LastEditors: Seven
 * @LastEditTime: 2021-09-22 16:22:27
-->
<template>
  <div class="label_tag">
    <span>常用客户标签：</span>
    <template v-for="item in items">
      <el-badge style="margin-right: 14px;cursor: pointer;" :value="item.count?item.count: ''" :max="99" type="danger" :key="item.defLabelId">
        <el-tag @click.native="serachClick(item)" :style="{color: item.click?'#fff':''}" :color="item.click?'#8080ff':''" :type="item.color" effect="plain">{{item.defLabelName}}</el-tag>
      </el-badge>
    </template>
    <span @click="addLabelClick" style="color: #47cb89;cursor: pointer;">+设置常用标签</span>
    <!-- 添加标签 -->
    <HqModal width="550" @on-cancel="cancel" :visible="labelVisible">
      <div class="main_label">
        <div class="main_box">
          <div class="li" v-for="(item,index) in navList" :key="index" :class="{active:index ==num}" @click="tab(index)">
            {{item}}
          </div>
        </div>
        <div class="tabCon">
          <span class="tab_con_clue">提示：1、最多可选6个常用标签，按照选择先后排序</span>
          <p class="tab_con_clue">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、点击标签，选中。再次点击，取消选择</p>
          <!-- 全部 -->
          <div v-show="0 == num" v-for="(i, index) in tabContents" :key="index">
            <li class="tab_com_li">{{ i.titleName }}</li>
            <div class="tab_com_tag" v-if="i.type === 'sys'">
              <template v-for="v in i.sysLabels">
                <span 
                  v-if="seeSelect?v.used:true"
                  :key="v.sysLabelId"
                  class="tag_list"
                  :style="{backgroundColor: `${v.used?'#19be6b':''}`, color: `${v.used?'#fff':''}`}"
                  @click="tagListClick(v)">{{v.sysLabelName}}</span>
              </template>
            </div>
            <div class="tab_com_tag" v-if="i.type === 'biz'">
              <template v-for="v in i.bizCustomerLabels">
                <span 
                  v-if="seeSelect?v.used:true"
                  :key="v.labelId"
                  class="tag_list"
                  :style="{backgroundColor: `${v.used?'#19be6b':''}`, color: `${v.used?'#fff':''}`}"
                  @click="tagListClick(v)">{{v.name}}</span>
              </template>
            </div>
          </div>
          <!-- 非全部 -->
          <div v-for="(item, index) in tabContents" :key="index + item.titleName" v-show="(index + 1) == num">
            <li class="tab_com_li">{{ item.titleName }}</li>
            <div class="tab_com_tag" v-if="item.type === 'sys'">
              <template v-for="v in item.sysLabels">
                <span 
                v-if="seeSelect?v.used:true"
                class="tag_list" 
                :key="v.sysLabelId"
                :style="{backgroundColor: `${v.used?'#19be6b':''}`, color: `${v.used?'#fff':''}`}"
                @click="tagListClick(v)">{{v.sysLabelName}}</span> 
              </template>
            </div>
            <div class="tab_com_tag" v-if="item.type === 'biz'">
              <template v-for="v in item.bizCustomerLabels">
                <span 
                v-if="seeSelect?v.used:true"
                class="tag_list" 
                :key="v.labelId"
                :style="{backgroundColor: `${v.used?'#19be6b':''}`, color: `${v.used?'#fff':''}`}"
                @click="tagListClick(v)">{{v.name}}</span> 
              </template>
            </div>
          </div>
        </div>
      </div>
      
      <div slot="footer">
         <el-checkbox style="margin-right: 220px" @change="checkChange" v-model="single">仅查看已选标签</el-checkbox>
         <el-button size="small" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="saveClick" :loading="addloading">确定</el-button>
      </div>
    </HqModal>
  </div>
</template>
<script>
import { getDefLableGetDef, getDefLableList, doDefLableSave } from "api/customer"
import { mapGetters } from 'vuex'

export default {
  props: ['isClick', 'defaultClick'], // isclick 防止快速点击标签查询时引起的一起调用很多查询接口的问题
  data() {
    return {
      items: [],
      labelVisible: false,
      navList:['全部', '系统标签', '客户标签'],
      tabContents:[],
      num: 0,
      // 是否查看选中的标签
      single: false,
      // 保存按钮
      addloading: false,
      trackType: '',
      customerId: '',
      labelList: [],
      labelArr: [],
      labelColor: ['success', '', 'warning', 'danger', 'success', 'warning'],
      // 已选客户标签排序
      labelSort: [],
      // 已选系统标签排序
      sysLabelSort: [],
      // 查看已选标签
      seeSelect: false,
      defaultLabel: ''
    }
  },
  computed: {
    ...mapGetters(['getCustomerLabelList'])
  },
  mounted() {
    this.$nextTick(() => {
      this.getDefLableGetDef()
    })
  },
  methods: {
    // 获取系统标签和客户标签
    getDefLableGetDef() {
      this.defaultLabel = ""
      getDefLableGetDef().then(data => {
        let { code, result } = data
        if(code === 0) {
          this.init(result)
          this.labelArr = result
        }
      })
    },
    init(arr, customer) {
      let _this = this
      this.sysLabelSort = []
      this.labelSort = []
      arr.map((item, index) => {
        if(this.defaultLabel === item.defLabelId) {
          if(customer) {
            item.click = false  
          } else {
            this.$set(item, 'click', true)
          }
        } else {
          item.click = false
        }
        item.color = this.labelColor[index]
        if(item.defLabelType === 2) {
          _this.labelSort.push(item.defLabelId)
        } else {
          _this.sysLabelSort.push(item.defLabelId)
        }
      })
      this.items = arr
      // this.$forceUpdate()
    },
    // 查询 
    serachClick(item) {
      if(!this.isClick) return false
      this.items.map(i => {
        if(item.defLabelId === i.defLabelId) {
          if(i.click) {
            this.defaultLabel = ''
            i.click = false
            this.$emit('searchList', {labelTrckType: '', labelId: ''})
          } else {
            this.defaultLabel = item.defLabelId
            i.click = true
            this.$emit('searchList', {labelTrckType: item.defLabelType, labelId: item.defLabelId})
          }
        } else {
          i.click = false
        }
      })
    },
    // 打开设置常用标签
    async addLabelClick() {
      // 客户标签
      const data = await getDefLableList()
      let { code, result } = data

      this.tabContents = []
      let _this = this
      if(code === 0) {
        let tabContents = [
          { titleName: '系统标签', sysLabels: result.sysLabels, type: 'sys' },
          { titleName: '客户标签', bizCustomerLabels: result.bizCustomerLabels, type: 'biz'}
        ]
        this.tabContents = tabContents
        this.labelVisible = true
      }
    },
    // 保存添加
    async saveClick() {
      if(this.sysLabelSort.length + this.labelSort.length > 6) {
        this.$message.error('常用标签最多选6个')
        return false
      }
      const data = await doDefLableSave(this.labelSort.toString(), this.sysLabelSort.toString())
      if(data.code === 0) {
        this.$message.success(data.msg)
        this.getDefLableGetDef()
      } else {
        this.$message.error(data.msg)
      }
      this.labelVisible = false
    },
    // 查看已选标签
    checkChange(value) {
      this.seeSelect = value
    },
    // 取消
    cancel () {
      this.getDefLableGetDef()
      this.labelVisible = false
    },
    tab (index){
      this.num = index
    },
    // 选择事件
    tagListClick(node) {
      // 这里是为了已选的标签排序
      if(node.sysLabelId) {
        let i = this.sysLabelSort.indexOf(node.sysLabelId)
        if(i === -1) {
          this.sysLabelSort.push(node.sysLabelId)
        } else {
          this.sysLabelSort.splice(i, 1)
        }
      } else if(node.labelId) {
        let j = this.labelSort.indexOf(node.labelId)
        if(j === -1) {
          this.labelSort.push(node.labelId)
        } else {
          this.labelSort.splice(j, 1)
        }
      }
      this.tabContents.map((item, index) => {
        if(item.type === 'sys') {
          item.sysLabels.map(i => {
            if(node.sysLabelId === i.sysLabelId) {
              i.used = !i.used
            }
          })
        } else {
          item.bizCustomerLabels.map(i => {
            if(node.labelId === i.labelId) {
              i.used = !i.used
            }
          })
        }
      })
    }
  },
}
</script>
<style lang="scss" scope>
  .label_tag {
    padding-top: 16px;
  }
  .main_label {
        display: flex;
      }

      .main_box {
        flex: 1;
        border-right: 1px solid #ebeef5;
        margin-top: 50px;
        .li{
          list-style:none;
          height:30px;
          line-height:30px;
          cursor: pointer;
          text-align:center;
          font-weight: 600;
        }
        .active {
          background-color: #ccf3fd;
        }
      }
    .tabCon {
      flex: 5;

      .tab_con_clue {
        line-height: 22px;
        transform: scale(0.9);
        margin-left: 8px;
      }

      .tab_com_li {
        margin: 10px 0 0 10px;
        font-weight: 600;
      }
      .tab_com_tag {
        margin: 10px 0 0 40px;

        .tag_list {
          display: inline-block;
          line-height: 24px;
          font-size: 12px;
          padding: 0 6px;
          text-align: center;
          border: 1px solid #ebeef5;
          cursor: pointer;
          margin-bottom: 14px;
          min-width: 62px;
        }
      }
    }
</style>