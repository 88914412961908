<!--
 * @Description: 导入
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-05-07 17:06:51
 * @LastEditors: crm
 * @LastEditTime: 2024-01-15 17:03:52
-->
<template>
  <span>
    <el-button
      type="success"
      class="hq-button"
      icon="el-icon-upload"
      @click="importClick"
      >导入</el-button
    >
    <!-- 导入 -->
    <HqModal :visible="importModel" title="导入" @on-cancel="close">
      <el-form ref="form" :model="form" :rules="formRules">
        <el-row :gutter="8">
          <el-col :span="24" style="width: 100%">
            <el-form-item prop="trackType" v-if="$store.state.user.loginUser.trackType == 0">
              <dict-select code="BIZ_TYPE">
                <v-select
                  v-model="form.trackType"
                  slot-scope="props"
                  :items="props.dictData"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="赛道"
                  item-text="label"
                  item-value="value"
                  outlined
                  :dense="true"
                  :height="32"
                  hide-details="auto"
                  @change="trackTypeChange"
                ></v-select>
              </dict-select>
            </el-form-item>

            <el-form-item prop="trackType" v-else-if="$store.state.user.loginUser.trackType == 2">
              <dict-select code="BIZ_TYPE">
                <v-select
                  v-model="form.trackType"
                  :items="[{dataType:1,label:'自考项目',value: 2}]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="赛道"
                  item-text="label"
                  item-value="value"
                  outlined
                  :dense="true"
                  :height="32"
                  hide-details="auto"
                  @change="trackTypeChange"
                ></v-select>
              </dict-select>
            </el-form-item>
            <el-form-item prop="trackType" v-else-if="$store.state.user.loginUser.trackType == 1">
              <dict-select code="BIZ_TYPE">
                <v-select
                  v-model="form.trackType"
                  :items="[{dataType:1,label:'财经项目',value: 1}]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="赛道"
                  item-text="label"
                  item-value="value"
                  outlined
                  :dense="true"
                  :height="32"
                  hide-details="auto"
                  @change="trackTypeChange"
                ></v-select>
              </dict-select>
            </el-form-item>

            <el-form-item prop="trackType" v-else-if="$store.state.user.loginUser.trackType == 3">
              <dict-select code="BIZ_TYPE">
                <v-select
                  v-model="form.trackType"
                  :items="[{dataType:1,label:'财会项目',value: 3}]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="赛道"
                  item-text="label"
                  item-value="value"
                  outlined
                  :dense="true"
                  :height="32"
                  hide-details="auto"
                  @change="trackTypeChange"
                ></v-select>
              </dict-select>
            </el-form-item>

            <el-form-item prop="trackType" v-else-if="$store.state.user.loginUser.trackType == 6">
              <dict-select code="BIZ_TYPE">
                <v-select
                  v-model="form.trackType"
                  :items="[{dataType:1,label:'思想公式',value: 6}]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="赛道"
                  item-text="label"
                  item-value="value"
                  outlined
                  :dense="true"
                  :height="32"
                  hide-details="auto"
                  @change="trackTypeChange"
                ></v-select>
              </dict-select>
            </el-form-item>

            <el-form-item prop="trackType" v-else>
              <dict-select code="BIZ_TYPE">
                <v-select
                  v-model="form.trackType"
                  :items="[{dataType:1,label:'教师在线',value: 4}]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="赛道"
                  item-text="label"
                  item-value="value"
                  outlined
                  :dense="true"
                  :height="32"
                  hide-details="auto"
                  @change="trackTypeChange"
                ></v-select>
              </dict-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <CheckBox 
              label="分配规则" 
              :list="checkList"
              @update:select="selectChange"
              v-bind:select.sync="form.notAllot"
              ref="check" />
          </el-col>
          <div  v-show="form.trackType !== ''">
          <!-- <div  v-show="(form.trackType !== '') || ($store.state.user.loginUser.trackType !== 0)"> -->
            <el-col :span="12">
              <span style="margin-right:10px">模板</span>
                <a :href='`${publicPath}static/resource/customer-new.xls`' v-if="form.notAllot == 5">请点击下载模板</a>
                <a :href='`${publicPath}static/resource/customertable.xls`' v-else>请点击下载模板</a>
            </el-col>
            <el-col :span="12" style="position: relative;">
              <el-form-item prop="file">
                <input v-model="form.file" style="display: none" />
                <el-button class="upload" type="primary"> 选择文件 </el-button>
                <input class="change" type="file"  ref="file"  style="left: 0;opacity: 0; position: absolute;width: 100px" @change="getFile()">
              </el-form-item>
            </el-col>
            <el-col v-if="form.file.name" :span="24">
              文件&nbsp;&nbsp; {{ form.file.name }}
            </el-col>
          </div>
          <el-col :span="24" v-if="form.notAllot == 2 || form.notAllot == 1">
            <el-form-item prop="deptId">
              <SelectTree
                labelName="部门"
                ref="deptsys"
                :data="deptList"
                label="deptName"
                value="deptId"
                @update:select="select"
                v-bind:select.sync="form.deptId" />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.notAllot == 3">
            <el-form-item prop="areaId">
              <SelectTree
                labelName="区域"
                ref="area"
                :data="areaList"
                label="areaName"
                value="areaId"
                v-bind:select.sync="form.areaId" />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.notAllot == 1">
            <el-form-item prop="userId">
              <v-autocomplete
                v-model="form.userId"
                :items="userArr"
                :menu-props="{ bottom: true, offsetY: true }"
                label="人员"
                outlined
                item-text="userName"
                item-value="userId"
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                rounded
              ></v-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <el-button
          type="primary"
          @click="importSave()"
          :loading="importloading"
          >确定</el-button
        >
        <el-button
          type="error"
          @click="cancel()"
          style="margin-left: 8px"
          >重置</el-button
        >
      </template>
    </HqModal>
  </span>
</template>
<script>
import SelectTree from "@/components/select-tree";
import dictSelect from "@/components/dictSelect";
import CheckBox from "@/components/check-box";
import { getUserByDeptId, getDeptByTrackType, getTrackAreaTree } from "api/public";
import { doImportCustomer } from "api/customer";
export default {
  components: {
    SelectTree,
    dictSelect,
    CheckBox
  },
  props: {
    method: {
      type: Function,
    },
  },
  data() {
    return {
      importModel: false,
      importloading: false,
      checkList: [
        { label: "不分配", value: 0 },
        { label: "指定分配", value: 1 },
        { label: "按部门自动分配", value: 2 },
        { label: "按区域自动分配", value: 3 },
        { label: "按号码归属地分配", value: 4 },
        { label: "按指定部门分配", value: 5 }
      ],
      form: {
        trackType: "",
        notAllot: 1,
        file: "",
        deptId: "",
        areaId: "",
        userId: ""
      },
      formRules: {
        userId: [
          { required: true, message: '请选择人员' }
        ],
        file: [
          { required: true, message: '请选择文件' }
        ],
        trackType: [
          {message: '请选择赛道', required: true}
        ],
        areaId: [
          { required: true, message: '请选择区域' }
        ]
      },
      userArr: [],
      deptList: [],
      areaList: [],
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    importClick() {
      this.importModel = true
      this.$refs.check.set(this.form.notAllot)
    },
    getFile () {
      this.form.file = this.$refs.file.files[0]
    },
    selectChange(value) {
      this.form.notAllot = Number(value)
      console.log(Boolean((this.form.notAllot === 2 || this.form.notAllot === 1? 1 : 0)), "11111");
      if((this.form.notAllot === 1 || this.form.notAllot === 2) && this.form.deptId !== '') {
        this.$refs.deptsys.init()
      } 
      this.form.areaName = ''
      this.form.areaId = ''
      this.form.userId = ''
    },
    // 赛道联动部门
    trackTypeChange () {
      this.form.notAllot = 1
      this.$refs.check.set(this.form.notAllot)
      this.form.allotRole = ''
      let params = {trackType: this.form.trackType, isRoot: false}
      if (this.form.notAllot === 1 || this.form.notAllot === 2) {
        this.$refs.deptsys && this.$refs.deptsys.init()
      }
      this.form.deptId = ''
      this.form.userId = ''
      this.form.areaId = ''
      this.form.areaName = ''
      console.log("this.form.trackType", this.form.trackType);
      if (this.form.trackType == 2) {
        this.checkList = [
          { label: "不分配", value: 0 },
          { label: "指定分配", value: 1 },
          { label: "按部门自动分配", value: 2 },
          { label: "按区域自动分配", value: 3 },
          { label: "按指定部门分配", value: 5 }
        ]
      } else {
          this.checkList = [
            { label: "不分配", value: 0 },
            { label: "指定分配", value: 1 },
            { label: "按部门自动分配", value: 2 },
            { label: "按区域自动分配", value: 3 },
            { label: "按号码归属地分配", value: 4 },
            { label: "按指定部门分配", value: 5 }
          ]
      }
      getDeptByTrackType(params).then(res => {
        if (res.code === 0) {
          this.deptList = res.result.tree
        }
      })
      getTrackAreaTree({trackType: this.form.trackType}).then(data => {
          let {code, msg, result} = data
          if (code === 0) {
            this.areaList = result.tree
          } else {
            this.$message.error(msg)
          }
        })
    },
    select(deptId) {
      this.weightsUser = [];
      this.form.userId = [];
      this.form.deptId = deptId;
      if(deptId == '') {
        this.userArr = []
      } else {
        getUserByDeptId({ treeValue: deptId }).then(data => {
          this.userArr = data.result;
        });
      }
    },
    importSave() {
      this.form.allotRole = this.form.notAllot === 4?1: this.form.notAllot === 5? 2 : '';
      let notAllot = ''
      if (this.form.notAllot === 0) {
        notAllot = true
      } else {
        notAllot = false
      }
      
      
      let dataForm = new FormData()
      dataForm.append('file', this.form.file)
      dataForm.append('deptId', this.form.deptId)
      // dataForm.append('deptName', this.form.deptName)
      dataForm.append('areaId', this.form.areaId)
      // dataForm.append('areaName', this.form.areaName)
      dataForm.append('notAllot', notAllot)
      if ((this.form.notAllot == 'true' ? 0 : 1)) {
        dataForm.append('userId', this.form.userId)
      } else {
        dataForm.append('userId', '')
      }
      dataForm.append('trackType', this.form.trackType)
      dataForm.append('allotRole', this.form.allotRole)
      
      
       this.$refs['form'].validate((valid) => {
        if(valid) {
          if((this.form.notAllot === 1 || this.form.notAllot === 2) && this.form.deptId === '') {
            this.$message.info('请选择部门')
            return false
          }
          this.form.notAllot !== 0
          this.importloading = true
          doImportCustomer(dataForm).then((data) => {
            const { code, msg } = data
            if (code === 0) {
              this.$message.success(msg)
              this.method()
              this.close()
            } else {
              this.$message.error(msg)
            }
            this.importloading = false
          }).catch(() => {
            this.importModel = false
            this.importloading = false
          })
        }
       })
    },
    cancel() {
      this.$refs.file.value = ""
      this.form = {
        trackType: "",
        notAllot: 1,
        file: "",
        deptId: "",
        areaId: "",
        userId: ""
      }
      this.$refs.deptsys && this.$refs.deptsys.init()
      this.$refs.area && this.$refs.area.init()
      this.$forceUpdate();
    },
    close() {
      this.cancel()
      this.importModel = false
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
