var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-button",
        {
          staticClass: "hq-button",
          attrs: { type: "success", icon: "el-icon-upload" },
          on: { click: _vm.importClick }
        },
        [_vm._v("导入")]
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.importModel, title: "导入" },
          on: { "on-cancel": _vm.close },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.importloading },
                      on: {
                        click: function($event) {
                          return _vm.importSave()
                        }
                      }
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "8px" },
                      attrs: { type: "error" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.formRules } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 8 } },
                [
                  _c(
                    "el-col",
                    { staticStyle: { width: "100%" }, attrs: { span: 24 } },
                    [
                      _vm.$store.state.user.loginUser.trackType == 0
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "trackType" } },
                            [
                              _c("dict-select", {
                                attrs: { code: "BIZ_TYPE" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(props) {
                                        return _c("v-select", {
                                          attrs: {
                                            items: props.dictData,
                                            "menu-props": {
                                              bottom: true,
                                              offsetY: true
                                            },
                                            label: "赛道",
                                            "item-text": "label",
                                            "item-value": "value",
                                            outlined: "",
                                            dense: true,
                                            height: 32,
                                            "hide-details": "auto"
                                          },
                                          on: { change: _vm.trackTypeChange },
                                          model: {
                                            value: _vm.form.trackType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "trackType",
                                                $$v
                                              )
                                            },
                                            expression: "form.trackType"
                                          }
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3662489214
                                )
                              })
                            ],
                            1
                          )
                        : _vm.$store.state.user.loginUser.trackType == 2
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "trackType" } },
                            [
                              _c(
                                "dict-select",
                                { attrs: { code: "BIZ_TYPE" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: [
                                        {
                                          dataType: 1,
                                          label: "自考项目",
                                          value: 2
                                        }
                                      ],
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "赛道",
                                      "item-text": "label",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      "hide-details": "auto"
                                    },
                                    on: { change: _vm.trackTypeChange },
                                    model: {
                                      value: _vm.form.trackType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "trackType", $$v)
                                      },
                                      expression: "form.trackType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm.$store.state.user.loginUser.trackType == 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "trackType" } },
                            [
                              _c(
                                "dict-select",
                                { attrs: { code: "BIZ_TYPE" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: [
                                        {
                                          dataType: 1,
                                          label: "财经项目",
                                          value: 1
                                        }
                                      ],
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "赛道",
                                      "item-text": "label",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      "hide-details": "auto"
                                    },
                                    on: { change: _vm.trackTypeChange },
                                    model: {
                                      value: _vm.form.trackType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "trackType", $$v)
                                      },
                                      expression: "form.trackType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm.$store.state.user.loginUser.trackType == 3
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "trackType" } },
                            [
                              _c(
                                "dict-select",
                                { attrs: { code: "BIZ_TYPE" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: [
                                        {
                                          dataType: 1,
                                          label: "财会项目",
                                          value: 3
                                        }
                                      ],
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "赛道",
                                      "item-text": "label",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      "hide-details": "auto"
                                    },
                                    on: { change: _vm.trackTypeChange },
                                    model: {
                                      value: _vm.form.trackType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "trackType", $$v)
                                      },
                                      expression: "form.trackType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm.$store.state.user.loginUser.trackType == 6
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "trackType" } },
                            [
                              _c(
                                "dict-select",
                                { attrs: { code: "BIZ_TYPE" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: [
                                        {
                                          dataType: 1,
                                          label: "思想公式",
                                          value: 6
                                        }
                                      ],
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "赛道",
                                      "item-text": "label",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      "hide-details": "auto"
                                    },
                                    on: { change: _vm.trackTypeChange },
                                    model: {
                                      value: _vm.form.trackType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "trackType", $$v)
                                      },
                                      expression: "form.trackType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            { attrs: { prop: "trackType" } },
                            [
                              _c(
                                "dict-select",
                                { attrs: { code: "BIZ_TYPE" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: [
                                        {
                                          dataType: 1,
                                          label: "教师在线",
                                          value: 4
                                        }
                                      ],
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "赛道",
                                      "item-text": "label",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      "hide-details": "auto"
                                    },
                                    on: { change: _vm.trackTypeChange },
                                    model: {
                                      value: _vm.form.trackType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "trackType", $$v)
                                      },
                                      expression: "form.trackType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("CheckBox", {
                        ref: "check",
                        attrs: {
                          label: "分配规则",
                          list: _vm.checkList,
                          select: _vm.form.notAllot
                        },
                        on: {
                          "update:select": [
                            _vm.selectChange,
                            function($event) {
                              return _vm.$set(_vm.form, "notAllot", $event)
                            }
                          ]
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.trackType !== "",
                          expression: "form.trackType !== ''"
                        }
                      ]
                    },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [_vm._v("模板")]
                        ),
                        _vm.form.notAllot == 5
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.publicPath +
                                    "static/resource/customer-new.xls"
                                }
                              },
                              [_vm._v("请点击下载模板")]
                            )
                          : _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.publicPath +
                                    "static/resource/customertable.xls"
                                }
                              },
                              [_vm._v("请点击下载模板")]
                            )
                      ]),
                      _c(
                        "el-col",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { span: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "file" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.file,
                                    expression: "form.file"
                                  }
                                ],
                                staticStyle: { display: "none" },
                                domProps: { value: _vm.form.file },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "file",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "upload",
                                  attrs: { type: "primary" }
                                },
                                [_vm._v(" 选择文件 ")]
                              ),
                              _c("input", {
                                ref: "file",
                                staticClass: "change",
                                staticStyle: {
                                  left: "0",
                                  opacity: "0",
                                  position: "absolute",
                                  width: "100px"
                                },
                                attrs: { type: "file" },
                                on: {
                                  change: function($event) {
                                    return _vm.getFile()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.form.file.name
                        ? _c("el-col", { attrs: { span: 24 } }, [
                            _vm._v(
                              " 文件   " + _vm._s(_vm.form.file.name) + " "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.form.notAllot == 2 || _vm.form.notAllot == 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "deptId" } },
                            [
                              _c("SelectTree", {
                                ref: "deptsys",
                                attrs: {
                                  labelName: "部门",
                                  data: _vm.deptList,
                                  label: "deptName",
                                  value: "deptId",
                                  select: _vm.form.deptId
                                },
                                on: {
                                  "update:select": [
                                    _vm.select,
                                    function($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "deptId",
                                        $event
                                      )
                                    }
                                  ]
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.notAllot == 3
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "areaId" } },
                            [
                              _c("SelectTree", {
                                ref: "area",
                                attrs: {
                                  labelName: "区域",
                                  data: _vm.areaList,
                                  label: "areaName",
                                  value: "areaId",
                                  select: _vm.form.areaId
                                },
                                on: {
                                  "update:select": function($event) {
                                    return _vm.$set(_vm.form, "areaId", $event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.notAllot == 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "userId" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.userArr,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "人员",
                                  outlined: "",
                                  "item-text": "userName",
                                  "item-value": "userId",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto",
                                  rounded: ""
                                },
                                model: {
                                  value: _vm.form.userId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "userId", $$v)
                                  },
                                  expression: "form.userId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }